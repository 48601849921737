import "./Resources.css";

import React from "react";

class Traps extends React.Component {
  render() {
    return (
      <div className="Modal">
        <div className="ModalHead">
          <img
            className="ModalIcon"
            src={require("../../../Images/Res2.png").default}
          />
          <h1 className="ModalTitle">Traps</h1>
        </div>
        <div className="ModalBody">
          <div id="why" className="ModalBodyCard">
            <h3 className="ModalBodyTitleResource">
              Work environment conditions that make it more likely for people to
              make mistakes <br /> <br /> The ten most predominant human error
              TRAPs include:
            </h3>
            <hr className="ModalHrLine" />
            <ul>
              <li key="1" id="ModalListLiResource">
                Time Pressure
              </li>
              <li key="2" id="ModalListLiResource">
                Distraction/Interruption
              </li>
              <li key="3" id="ModalListLiResource">
                Multiple Tasks
              </li>
              <li key="4" id="ModalListLiResource">
                Overconfidence
              </li>
              <li key="5" id="ModalListLiResource">
                Vague or Interpretive Guidance
              </li>
              <li key="6" id="ModalListLiResource">
                First Shift/Last Shift
              </li>
              <li key="7" id="ModalListLiResource">
                Peer Pressure
              </li>
              <li key="8" id="ModalListLiResource">
                Change/Off-Normal
              </li>
              <li key="9" id="ModalListLiResource">
                Physical Environment
              </li>
              <li key="10" id="ModalListLiResource">
                Mental Stress
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default Traps;
