import "./Resources.css";

import React from "react";

class CodeOfHonor extends React.Component {
  render() {
    return (
      <div className="Modal">
        <div className="ModalHead">
          <img
            className="ModalIcon"
            src={require("../../../Images/Res3.png").default}
          />
          <h1 className="ModalTitle">Code Of Honor</h1>
        </div>
        <div className="ModalBody">
          <div id="whycode" className="ModalBodyCard">
            <ul>
              <li key="1" id="ModalListLiResource">
                Use timely effective communication
              </li>
              <li key="2" id="ModalListLiResource">
                Treat everyone with respect
              </li>
              <li key="3" id="ModalListLiResource">
                Be willing to call and be called
              </li>
              <li key="4" id="ModalListLiResource">
                Deal direct
              </li>
              <li key="5" id="ModalListLiResource">
                Be the example
              </li>
              <li key="6" id="ModalListLiResource">
                Learn, share, mentor
              </li>
              <li key="7" id="ModalListLiResource">
                Show up on time
              </li>
              <li key="8" id="ModalListLiResource">
                Do what you say
              </li>
              <li key="9" id="ModalListLiResource">
                Finish what you start
              </li>
              <li key="10" id="ModalListLiResource">
                Say “please” and “thank you”
              </li>
              <li key="11" id="ModalListLiResource">
                Celebrate all wins
              </li>
              <li key="12" id="ModalListLiResource">
                Create FUN!
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default CodeOfHonor;
