import React, { Component } from "react";

import BigCard from "../../components/BigCard";
import Card from "../../components/Card";
import Link from "gatsby-link";
import NotificationSystem from "react-notification-system";
import Resources from "./Resources";

class ENGHome extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);

    this.state = {
      _notificationSystem: null,
    };
  }

  componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem;

    /*
        
        this._notificationSystem.addNotification({
            
            level: 'success',
            position: 'tc',
            title: 'Success'
            
    
        });

        */
  }

  handleClick() {
    //replace('/eng/Tools')
  }

  render() {
    var style = {
      NotificationItem: {
        // Override the notification item
        DefaultStyle: {
          // Applied to every notification, regardless of the notification level
          margin: "10px 5px 2px 1px",
        },

        success: {
          // Applied only to the success notification item
        },
      },
    };

    return (
      <div>
        <div className="BigCardGroup">
          <Link to="/eng/Tools">
            <BigCard
              icon={require("../../Images/toolscover.png").default}
              title="Error Elimination Tools"
              text="These are the same behavioral tools developed through 20 years of use and refinement within the US commercial nuclear generation and airline industries."
              image={require("../../Images/background_1.jpg")}
            />
          </Link>
        </div>

        <h1 className="Title">Resources</h1>
        <Resources />
        <NotificationSystem ref="notificationSystem" style={style} />
      </div>
    );
  }
}

export default ENGHome;
