import "./Resources.css";

import React from "react";

class Glossary extends React.Component {
  render() {
    return (
      <div className="Modal">
        <div className="ModalHead">
          <img
            className="ModalIcon"
            src={require("../../../Images/Res1.png").default}
          />
          <h1 className="ModalTitle">Glossary</h1>
        </div>
        <div className="ModalBody">
          <div id="why" className="ModalBodyCard">
            <h3 className="ModalBodyTitleResource">Leader</h3>
            <hr className="ModalHrLine" />
            <p id="ModalResourceP">
              You are a leader when you take full responsibility for your own
              behaviors, and positively influence the actions and behaviors of
              others.
            </p>
          </div>
          <div id="when" className="ModalBodyCard">
            <h3 className="ModalBodyTitleResource">
              Proactive Accountability®
            </h3>
            <hr className="ModalHrLine" />
            <p id="ModalResourceP">
              Personal willingness to (1) accept full responsibility for all
              actions taken and (2) to anticipate and take action regarding
              challenges and opportunities.
            </p>
          </div>
          <div id="how" className="ModalBodyCard">
            <h3 className="ModalBodyTitleResource">
              Precepts Of Practicing Perfection®
            </h3>
            <hr className="ModalHrLine" />
            <ul>
              <li key="1" id="ModalListLiResource">
                Things are the way they are because they got that way
              </li>
              <li key="2" id="ModalListLiResource">
                84 to 94 percent of all human error can be directly attributed
                to process, programmatic, or organizational issues
              </li>
              <li key="3" id="ModalListLiResource">
                People come to work wanting to do a good job
              </li>
              <li key="4" id="ModalListLiResource">
                The people who do the work are the ones who have the answers
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default Glossary;
