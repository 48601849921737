import CodeOfHonor from "./Resources/CodeOfHonor";
import Glossary from "./Resources/Glossary";
import Modal from "react-responsive-modal";
import Phonetics from "./Resources/Phonetics";
import React from "react";
import SmallCard from "../../components/SmallCard";
import Traps from "./Resources/Traps";

class Resources extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  onOpenModal = (id) => {
    this.setState({
      open: {
        [id]: true,
      },
    });
  };

  onCloseModal = (id) => {
    this.setState({
      open: {
        [id]: false,
      },
    });
  };

  render() {
    const { open } = this.state;

    let data = [
      {
        id: 1,
        title: "Glossary",
        image: require("../../Images/wallpaper.jpg").default,
        logo: require("../../Images/Res1.png").default,
        comp: <Glossary />,
      },
      {
        id: 2,
        title: "Traps",
        image: require("../../Images/wallpaper.jpg").default,
        logo: require("../../Images/Res2.png").default,
        comp: <Traps />,
      },
      {
        id: 3,
        title: "Code Of Honor",
        image: require("../../Images/wallpaper.jpg").default,
        logo: require("../../Images/Res3.png").default,
        comp: <CodeOfHonor />,
      },
      {
        id: 4,
        title: "Phonetics",
        image: require("../../Images/wallpaper.jpg").default,
        logo: require("../../Images/Res4.png").default,
        comp: <Phonetics />,
      },
    ];

    return (
      <div className="SmallCardGroup">
        {data.map(function (i, index) {
          return (
            <div key={i.id}>
              <a key={i.id} onClick={this.onOpenModal.bind(this, i.id)}>
                <SmallCard
                  logo={i.logo}
                  title={i.title}
                  image={data.image}
                ></SmallCard>
              </a>

              <Modal
                className="Modal"
                open={open[i.id]}
                onClose={this.onCloseModal}
              >
                {i.comp}
              </Modal>
            </div>
          );
        }, this)}
      </div>
    );
  }
}

export default Resources;
