import "./Resources.css";

import React from "react";

class Phonetics extends React.Component {
  render() {
    return (
      <div className="Modal">
        <div className="ModalHead">
          <img
            className="ModalIcon"
            src={require("../../../Images/Res4.png").default}
          />
          <h1 className="ModalTitle">Glossary</h1>
        </div>
        <div className="ModalBody">
          <div id="why" className="ModalBodyCard">
            <h3 className="ModalBodyTitleResource">Phonetic Alphabet</h3>
            <hr className="ModalHrLine" />
            <ul>
              <table className="tablestyle">
                <tbody className="tbodystyle">
                  <tr className="trowstyle">
                    <td>A</td>
                    <td>Alpha</td>
                    <td>&nbsp;</td>
                    <td>N</td>
                    <td>November</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>B</td>
                    <td>Bravo</td>
                    <td>&nbsp;</td>
                    <td>O</td>
                    <td>Oscar</td>
                  </tr>
                  <tr>
                    <td>C</td>
                    <td>Charlie</td>
                    <td>&nbsp;</td>
                    <td>P</td>
                    <td>Papa</td>
                  </tr>
                  <tr>
                    <td>D</td>
                    <td>Delta</td>
                    <td>&nbsp;</td>
                    <td>Q</td>
                    <td>Quebec</td>
                  </tr>
                  <tr>
                    <td>E</td>
                    <td>Echo</td>
                    <td>&nbsp;</td>
                    <td>R</td>
                    <td>Romeo</td>
                  </tr>
                  <tr>
                    <td>F</td>
                    <td>Foxtrot</td>
                    <td>&nbsp;</td>
                    <td>S</td>
                    <td>Sierra</td>
                  </tr>
                  <tr>
                    <td>G</td>
                    <td>Golf</td>
                    <td>&nbsp;</td>
                    <td>T</td>
                    <td>Tango</td>
                  </tr>
                  <tr>
                    <td>H</td>
                    <td>Hotel</td>
                    <td>&nbsp;</td>
                    <td>U</td>
                    <td>Uniform</td>
                  </tr>
                  <tr>
                    <td>I</td>
                    <td>India</td>
                    <td>&nbsp;</td>
                    <td>V</td>
                    <td>Victor</td>
                  </tr>
                  <tr>
                    <td>J</td>
                    <td>Juliette</td>
                    <td>&nbsp;</td>
                    <td>W</td>
                    <td>Whiskey</td>
                  </tr>
                  <tr>
                    <td>K</td>
                    <td>Kilo</td>
                    <td>&nbsp;</td>
                    <td>X</td>
                    <td>X-Ray</td>
                  </tr>
                  <tr>
                    <td>L</td>
                    <td>Lima</td>
                    <td>&nbsp;</td>
                    <td>Y</td>
                    <td>Yankee</td>
                  </tr>
                  <tr>
                    <td>M</td>
                    <td>Mike</td>
                    <td>&nbsp;</td>
                    <td>Z</td>
                    <td>Zulu</td>
                  </tr>
                </tbody>
              </table>
            </ul>
          </div>

          <div id="when" className="ModalBodyCard">
            <h3 className="ModalBodyTitleResource">Phonetic Numerals</h3>
            <hr className="ModalHrLine" />
            <ul>
              <table className="tablestyle">
                <tbody>
                  <tr id="tabletr">
                    <td>0 - "zero"</td>
                    <td>7 - "seven"</td>
                  </tr>
                  <tr>
                    <td>1 - "one"</td>
                    <td>8 - "eight"</td>
                  </tr>
                  <tr>
                    <td>2 - "two"</td>
                    <td>9 - "nine"</td>
                  </tr>
                  <tr>
                    <td>3 - "three"</td>
                    <td>130 - "one three zero"</td>
                  </tr>
                  <tr>
                    <td>4 - "four"</td>
                    <td>500- "five hundred"</td>
                  </tr>
                  <tr>
                    <td>5 - "five"</td>
                    <td>1,600 - "one six hundred"</td>
                  </tr>
                  <tr>
                    <td>6 - "six"</td>
                    <td>17,000 - "one seven thousand"</td>
                  </tr>
                </tbody>
              </table>
            </ul>
            <h4 className="ModalBodyTitleResource">
              Numbers that sound similar (such as 16 and 60) should be
              communicated as individual numbers.
            </h4>
            <h4 className="ModalBodyTitleResource">
              <em>For example:&nbsp; </em>16 - "one six" and 60 - "six zero"
            </h4>
          </div>
        </div>
      </div>
    );
  }
}

export default Phonetics;
