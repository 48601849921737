import React, { Component } from "react";
import fire from "../../config/shit";
import firebase from "firebase";
import "../../pages/eng/index.css";
import NotificationSystem from "react-notification-system";
import ForgotForm from "./ForgotForm";

class ENGLogin extends React.Component {
  constructor(props) {
    super(props);
    this.logIn = this.logIn.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.signUp = this.signUp.bind(this);
    this.PasswordNotification = this.PasswordNotification.bind(this);

    this.state = {
      _notificationSystem: null,
      email: "",
      password: "",
      forgotemail: "",
      keysLocal: [""],
      keyInput: "",
      keyExists: "",
      keyStatus: [""],
      authenticated: false
    };
  }

  componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem;

  }

  PasswordNotification(event) {
    event.preventDefault();
    this._notificationSystem.addNotification({
      level: "info",
      position: "tc",
      title: "Reset Your Password",
      dismissible: "button",
      children: (
        <div>
          <ForgotForm />
        </div>
      )
    });
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  logIn(e) {
    e.preventDefault();

    firebase
      .auth()
      .signInWithEmailAndPassword(this.state.email, this.state.password)
      .then(u => {
        this._notificationSystem.addNotification({
          message: "Validaded",
          level: "Success",
          position: "tc",
          title: "issue"
        });
      })
      .catch(error => {
        console.log(error);

        var errormessage = String(error);
        console.log(errormessage);

        this._notificationSystem.addNotification({
          message: errormessage,
          level: "error",
          position: "tc",
          title: "Check Your Credentials"
        });
      });
  }

  signUp(e) {
    e.preventDefault();

    fire
      .auth()
      .createUserWithEmailAndPassword(this.state.email, this.state.password)
      .then(u => {
        //RTD Setup Start
        var userpush = firebase
          .database()
          .ref("ENG/Users")
          .push();

        // var key = userpush.key()

        userpush.set({
          UserInfo: {
            Email: this.state.email,
            Client: "Generic-ENG",
            Auth: true
            // id:key
          }
        });

        //console.log(key)

        //RTD Setup End
      })
      .catch(error => {
        console.log(error);
        var errormessage = String(error);
        console.log(errormessage);

        this._notificationSystem.addNotification({
          message: errormessage,
          level: "error",
          position: "tc",
          title: "Check Your Credentials"
        });
      });
  }

  render() {
    var style = {
      NotificationItem: {
        // Override the notification item
        DefaultStyle: {
          // Applied to every notification, regardless of the notification level
          margin: "10px 5px 2px 1px"
        },

        success: {
          // Applied only to the success notification item
          color: "white",
          background: "red"
        }
      }
    };

    return (
      <div className="FormContainer">
        <form className="Form">
          <div className="FormGroup">
            <label className="FormLabel">Email address</label>
            <input
              className="FormInput"
              value={this.state.email}
              onChange={this.handleChange}
              type="email"
              name="email"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter email"
            />
          </div>
          <div className="FormGroup">
            <label className="FormLabel">Password</label>
            <input
              className="FormInput"
              value={this.state.password}
              onChange={this.handleChange}
              type="password"
              name="password"
              id="exampleInputPassword1"
              placeholder=" Enter Password"
            />
          </div>

          <button className="FormButton" type="submit" onClick={this.logIn}>
            Login
          </button>
          <NotificationSystem ref="notificationSystem" style={style} />
          <button className="FormButton" onClick={this.signUp}>
            Signup
          </button>
          <button className="FormButton" onClick={this.PasswordNotification}>
            Forgot Password?
          </button>
        </form>
      </div>
    );
  }
}

export default ENGLogin;
